$(document).ready(function () {

    //Show hide blog posts by subject
    $('.js-blog-subject').on('click', function () {

        $('.js-load-more').show();

        var selected_id = $(this).attr('data_id');

        $('.js-blog-subject').removeClass('active');
        $(this).addClass('active');

        var url = "../php/ajax/news.php?action=change";

        var post_data = {
            start: 0,
            limit: 6,
            subject_id: selected_id
        }

        $.post(url, post_data, function (data) {

            try {
                var response = JSON.parse(data);

                console.log(response);

                $('.js-blog-posts').html(response.html);

            }
            catch (e) {
                dialogify(e + '<br /><hr /><br />' + data);
            }

        });
    });

    $('body').on('click', '.js-load-more', function () {

        var button = $(this);

        button.attr('data_text', button.text());
        button.text('Please wait');

        var showing = $('.js-post').length;

        var selected_id = parseInt($('.js-blog-subject.active').attr('data_id'));

        var url = "../php/ajax/news.php?action=load";

        var limit = 12;

        var post_data = {
            start: showing,
            limit: limit,
            subject_id: selected_id
        };

        $.post(url, post_data, function (data) {

            try {
                var response = JSON.parse(data);

                $('.js-blog-posts').append(response.html);

                button.text(button.attr('data_text'));

                //Scroll to new posts
                var goto = $('.js-post').eq($('.js-post').length - response.count);

                $('body, html').animate({
                    scrollTop: goto.offset().top - $('.js-header').height()
                }, 500);

                if (response.count < limit) {
                    $('.js-load-more').hide();
                }

                // adjustSameHeights();

            }
            catch (e) {
                dialogify(e + '<br /><hr /><br />' + data);
                button.text(button.attr('data_text'));
            }

        });
    });
});