$.fn.initCycle = function (input_options) {

    var options = {
        'slides': '> .js-cycle-slide',
        'swipe': true,
        'speed': 2000,
        'easing': 'easeOutCirc',
        'manualSpeed': 1000,
        'timeout': 8000,
        'next': '.js-cycle-next',
        'prev': '.js-cycle-prev',
        'log': false,
        'fx': 'scrollHorz'
    };

    $.extend(options, input_options);

    this.cycle(options);

};