$(document).ready(function () {

    $('.js-slideshow').initCycle({
        'slides': '> .js-slide',
        'pager': '.js-slideshow-pager',
        'pagerTemplate': '<span class="pager-dot"></span>',
        'pageActiveClass': 'active',
        'fx': 'fadeOut'
    });

    $('.js-gallery').initCycle({
        'fx': 'carousel',
        'slides': '> .js-gallery-slide',
        'carousel-fluid': true,
        'carousel-visible': 3,
        'prev': '.js-gallery-prev',
        'next': '.js-gallery-next',
        'pager': '.js-gallery-pager',
        'pagerTemplate': '<span class="pager-dot"></span>',
        'pageActiveClass': 'active',
    });
});