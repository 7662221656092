$(document).ready(function () {

    if ($('.js-map').length > 0) {
        $('.js-map-itself')
            .gmap3({
                center: [53.265838, -1.437273],
                zoom: 12,
                navigationControl: false,
                scrollwheel: false,
                streetViewControl: false,
                mapTypeControl: false,
                zoomControl: false,
                fullscreenControl: false
            })
            .infowindow({
                position: [53.265838, -1.437273],
                content: "<strong>CISCO</strong><br />Foxwood Industrial Park<br />Foxwood Road<br />Chesterfield<br />S41 9RN"
            }).then(function (infowindow) {
            infowindow.open(this.get(0)); // this.get(0) return the map (see "get" feature)
        });
    }

});