$(document).ready(function () {

    $('.js-other-input').fadeOut();

    $('.js-other-checkbox').on('change', function () {

        if ($(this).is(':checked')) {
            $('.js-other-input').fadeIn();
        } else {
            $('.js-other-input').fadeOut();
        }


    });

    $('.js-send-enquiry-form').on('click', function (e) {

        e.preventDefault();

        var button = $(this);
        var button_text = button.text();
        button.text('Sending...');

        var form = $(this).closest('.js-enquiry-form');
        var fields = form.find('input, textarea');

        //Validation
        var errors = [];
        var valid = true;
        var message = '';

        //Check required fields
        var response = form.checkRequiredFields();

        if (!response.valid) {
            valid = false;
            message = "Please fill in all required fields, it looks like you missed these vital fields... <ul><li>" + response.empty_fields.join('</li><li>') + "</li></ul>";
            errors.push(message);
        }

        if (valid) {

            var url = "../php/ajax/enquiry-form.php?action=send";

            var post_data = fields.serialize();

            $.post(url, post_data, function (data) {

                try {
                    response = JSON.parse(data);

                    if (response.success) {
                        dialogify('<h2>Thank you</h2><p>Thank you for your enquiry, we\'ll be in touch soon.</p>');
                        fields.val('');
                        fields.prop('checked', false);
                        button.text(button_text);

                    }
                    else {

                        message = response.errors.join('<br />');
                        dialogify(message);
                        button.text(button_text);
                    }
                }
                catch (e) {
                    dialogify(e + '<br /><hr /><br />' + data);
                    button.text(button_text);
                }

            });
        }
        else {
            //If errors happened tell the user
            if (!valid) {
                message = errors.join('<br />');
                dialogify(message);
                button.text(button_text);
            }
        }

    });

});